// extracted by mini-css-extract-plugin
export var blackinput = "UiKit-module--blackinput--qwxEZ";
export var blackslider = "UiKit-module--blackslider--zBHM+";
export var blackswitch = "UiKit-module--blackswitch--Z9s5z";
export var blueinput = "UiKit-module--blueinput--LyWie";
export var blueslider = "UiKit-module--blueslider--hT0bP";
export var blueswitch = "UiKit-module--blueswitch--InsFK";
export var buttoncomponent = "UiKit-module--buttoncomponent--dc36e";
export var color = "UiKit-module--color--Ob9BS";
export var colorpicker = "UiKit-module--colorpicker--hP0WC";
export var components = "UiKit-module--components--7RKNs";
export var dark = "UiKit-module--dark--lhZtf";
export var desktopcomponents = "UiKit-module--desktopcomponents--NJfVK";
export var greeninput = "UiKit-module--greeninput--SzAEd";
export var greenslider = "UiKit-module--greenslider--MYLhA";
export var greenswitch = "UiKit-module--greenswitch--JTIb7";
export var headline = "UiKit-module--headline--u1zyY";
export var icon = "UiKit-module--icon--KUjfE";
export var icons = "UiKit-module--icons--yilfE";
export var inputcomponent = "UiKit-module--inputcomponent--6ed1K";
export var light = "UiKit-module--light--dY5dI";
export var lightcolor = "UiKit-module--lightcolor--Q4cbR";
export var lighticon = "UiKit-module--lighticon--y3qZy";
export var purpleinput = "UiKit-module--purpleinput--HzxoH";
export var purpleslider = "UiKit-module--purpleslider--02IS+";
export var purpleswitch = "UiKit-module--purpleswitch--xsgTt";
export var radioitem = "UiKit-module--radioitem--d6ZJc";
export var redinput = "UiKit-module--redinput--ghwpG";
export var redslider = "UiKit-module--redslider--19cy6";
export var redswitch = "UiKit-module--redswitch--56SDv";
export var titlep = "UiKit-module--titlep--F6zab";
export var uikit = "UiKit-module--uikit--WkmdI";
export var whiteinput = "UiKit-module--whiteinput--hm1md";
export var whiteslider = "UiKit-module--whiteslider--ozJEL";
export var whiteswitch = "UiKit-module--whiteswitch--u0qdg";