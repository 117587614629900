// extracted by mini-css-extract-plugin
export var border = "TabMotion-module--border--4WCJG";
export var citem = "TabMotion-module--citem--Z6T0J";
export var colors = "TabMotion-module--colors--YlKOO";
export var content = "TabMotion-module--content--iGqgg";
export var lefttrans = "TabMotion-module--lefttrans--x59fN";
export var onebold = "TabMotion-module--onebold--mNNUL";
export var outer = "TabMotion-module--outer--bTEKX";
export var pitem = "TabMotion-module--pitem--3WWWa";
export var purple = "TabMotion-module--purple--fLKZm";
export var righttrans = "TabMotion-module--righttrans--TIT1B";
export var tabmove = "TabMotion-module--tabmove--AcG4Z";
export var tabselect = "TabMotion-module--tabselect--eBje8";
export var textselectone = "TabMotion-module--textselectone--wOuUL";
export var textselecttwo = "TabMotion-module--textselecttwo--cXYoW";
export var twobold = "TabMotion-module--twobold--Ky4lr";