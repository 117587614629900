// extracted by mini-css-extract-plugin
export var active = "UiComponents-module--active--0ioVW";
export var container = "UiComponents-module--container--mmIW-";
export var documentation = "UiComponents-module--documentation---3FQP";
export var documentationheading = "UiComponents-module--documentationheading--ubbU2";
export var documentationtext = "UiComponents-module--documentationtext--8Mm-K";
export var heading = "UiComponents-module--heading--wH0ga";
export var inactive = "UiComponents-module--inactive--KJmrC";
export var inner = "UiComponents-module--inner--CaeiA";
export var popover = "UiComponents-module--popover--KOFhM";
export var popoverButton = "UiComponents-module--popoverButton--LIkJB";
export var popoverPanel = "UiComponents-module--popoverPanel--eUO3h";
export var radiocontainer = "UiComponents-module--radiocontainer--tzhn9";
export var radiodescription = "UiComponents-module--radiodescription--ef9nl";
export var radioheading = "UiComponents-module--radioheading--iVCqe";
export var radiooption = "UiComponents-module--radiooption--MRFaf";
export var text = "UiComponents-module--text--jsKGK";
export var textcontainer = "UiComponents-module--textcontainer--g+9Nb";
export var tick = "UiComponents-module--tick--Y+kg5";
export var uicontainer = "UiComponents-module--uicontainer--oOOkP";
export var uicontainerinner = "UiComponents-module--uicontainerinner--K9fuC";